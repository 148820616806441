//ash plugins partial
//descriptions of custom plugins
//$.fn.ashImgGet = sets the src attribute to the value of the data-src attribute (called on non-svg browsers)
//$.fn.equalColumnWidth = set each item in a set of items to equal widths of each other
//$.fn.ashTab = custom tab element
//$.fn.equalHeights = set each item in a set of items to equal heights of each other
//$.fn.ashCountdown = counts down number of characters in a textarea or input to show user how many characters are left
//$.fn.ashFlexSlide = flexslide slider plugin
//$.fn.ashAccord = custom accordion plugin (still being used by facility search and the SF portal page)
//$.fn.ashCollapsible = custom accordion plugin (official accordion plugin)
//$.fn.ashExpDataGallery = custom accordion gallery plugin (special use cases, outfitted with aria controls and source ordering for mobile browsers)
//$.fn.ashFileUpload = custom file uploader
//$.fn.progressGraphInit = initializes donut graph
//$.fn.initViewMoreButton = initializes 'view more' button functionality
//$.fn.ajaxSearchInit = initializes ajax call on search form submit and returns results in the target container
//$.fn.returnCheckedInputsAsObject = iterates through target form and places checkbox name and value in an object
//$.fn.disableButtonDuringAjaxPost = disables submit button while ajax request is being made after form submit
//$.fn.ashTemplateInit = initiate dynamic template rendering
//$.fn.isOnScreen = checks to see if the object passed to it is within the browser viewport
//DateController = creates and controls the date controller and its container

//handle complete event after template content is prepended
(function ($) {
	//SVG INLINE IMAGE REPLACEMENT
	$.fn.ashImgGet = function () {
		var img = $(this);

		//setting it to a tiny URI first for an apparent IE8 bug. We would just replace src with data-src value, but if img doesn't...
		//... have src initially, then the next line below won't work. We may be able to take this line out after testing.
		img.attr('src', 'data:image/gif;base64,R0lGODlhAQABAIAAAP///////yH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==');

		img.attr('src', img.attr('data-src'));
	};

	//HEALTHY ROADS PROGRESS BAR
	$.fn.equalColumnWidth = function () {
		return this.each(function () {
			var obj = $(this),
				objItems = obj.find('li'),
				numbers = objItems.length;

			objItems.css('width', 98 / numbers + '%');
		});
	};

	$.fn.ashTab = function (options) {
		var defaults = {
			tabs: this.find('.ashTabTab > li'),
			cont: this.find('.ashTabContent > li'),
			activeClass: 'active-tab',
			iconClass: 'icon-ArrowRight'
		};

		var s = $.extend(defaults, options);

		return this.each(function () {
			var tabs = s.tabs,
				cont = s.cont,
				contLn = cont.length,
				contParent = cont.parent(),
				activeCont = s.cont.first(),
				activeClass = s.activeClass, //class added to the tabs
				activeT = $(tabs[activeCont.index()]), //set active tab to null, then check if it exists before removing active class (later)
				activeA = activeT.find('a'),
				activeIcon = $('<span class="' + s.iconClass + '"></span>');

			//hide all but first tabs content
			cont.not(activeCont).hide();
			//set active class to tab
			activeT.addClass(activeClass);
			activeA.prepend(activeIcon);

			//set parent height to tallest tab to avoid page height changing on tab change
			if (document.documentElement.clientWidth > 767) {
				for (var i = 0; i < contLn; i++) {
					if (contParent.height() < $(cont[i]).outerHeight()) {
						contParent.height($(cont[i]).outerHeight());
					}
					if (i === contLn) {
						setHeights();
					}
				}
			}

			function setHeights() {
				cont.each(function () {
					$(this).height(contParent.height());
				});
			}

			tabs.on('click', showCont);

			function showCont(e) {
				e.preventDefault();
				//get current tab
				var tab = $(e.target);
				var ind = tab.parent().index(),
					content = $(cont.get(ind)); //content is the content container whose index matches tab indexv

				//if activetab
				if (activeT) {
					//remove class from that tab
					activeT.removeClass(activeClass);
					activeT.find('span[class*=icon-]').remove();
				}
				//set new active tab
				activeT = tab.closest('.tab');
				activeT.addClass(activeClass);
				activeT.find('a').prepend('<span class="' + s.iconClass + '"></span>');

				//remove old content
				activeCont.hide();
				//set new active content
				activeCont = content;
				//show content
				content.show();
			}
		});
	};

	$.fn.equalHeights = function (options) {
		var defaults = {
			'minHeight': null,
			'maxHeight': null,
			'overflow': 'hidden'
		};

		var settings = $.extend({}, defaults, options);

		var minHeight = settings.minHeight,
			maxHeight = settings.maxHeight,
			overflow = settings.overflow;

		var tallest = (minHeight) ? minHeight : 0;

		this.each(function () {
			$(this).css("min-height", "auto");
			if ($(this).height() > tallest) {
				tallest = $(this).height();
			}
		});
		if ((maxHeight) && tallest > maxHeight) {
			tallest = maxHeight;
		}

		return this.each(function () {
			$(this).height(tallest).css("overflow", overflow);
		});
	};

	//ASH Text Countdown Plugin
	//To use, add the 'ash-countdown' class to the text field you want to apply the plug in to.
	//Then add a "data-ash-maxlength" attribute to the same text field and insert the character limit.
	//Finally, make sure you have a counter to display the amount of characters left. By default,
	//the counter is going to be span.ash-countdown-counter.
	$.fn.ashCountdown = function (options) {
		var defaults = {
			textField: $(this), //the text field
			counter: $(this).closest('label').find('.ash-countdown-counter') //counter text
		};

		var settings = $.extend({}, defaults, options);

		if (!settings.counter.length) {
			console.log('Warning: Cannot find the counter for ashCountdown()!');
		}

		return this.each(function () {
			var s = settings;

			function countdownInit() {
				var maxLength = parseInt(s.textField.attr('data-ash-maxlength')),
					textVal = s.textField.val(),
					currentLength = textVal.length;
				if (currentLength < maxLength) {
					s.counter.text(String(maxLength - currentLength) + ' characters left');
				}
				if (currentLength === maxLength) {
					s.counter.text('Max characters reached');
				}
				if (currentLength > maxLength) {
					s.counter.html('<span>' + String(maxLength - currentLength) + '</span> characters left');
				}
			}

			countdownInit();

			s.textField.on('keyup blur propertychange input', countdownInit);
		});
	};

	//TODO: Remove this plugin after a suitable grace period (03/04/2015)
	//ASH Flexible Slider Plugin v2
	$.fn.ashFlexSlide = function (options) {

		var defaults = {
			'reel': 'div.widget-ashFlexSlide-reel',
			'slide': 'div.widget-ashFlexSlide-slide',
			'leftArrw': 'a.ashFlexSlide-al',
			'rightArrw': 'a.ashFlexSlide-ar',
			'tabs': null,
			'anTime': 1000,
			'arrowsHidden': false,
			'timer': false,
			'tick': 6000,
			'hideBehindArrows': true,
			'noArrows': false,
			'resize': false,
			'resizeArrows': true
		};

		var settings = $.extend({}, defaults, options);

		return this.each(function (index) {
			var obj = $(this),
				o = settings,
				theReel = $(this).parent().find($(o.reel)),
				theSlides = $(this).parent().find($(o.slide)),
				prevArrow = $(this).parent().find($(o.leftArrw)),
				nextArrow = $(this).parent().find($(o.rightArrw)),
				tabNav = $(o.tabs),
				animTime = o.anTime,
				hideArrows = o.arrowsHidden,
				useTimer = o.timer,
				timerTick = o.tick,
				noBehindArrows = o.hideBehindArrows,
				woArrows = o.noArrows,
				sizerSel = o.sizerSel,
				resize = o.resize,
				resizeArrows = o.resizeArrows,
				slideWidth;

			if (woArrows === true) {
				nextArrow = prevArrow = null;
			}
			if (noBehindArrows === true) {
				var mainParContWidth = ($(this).parent().width());
				//create new div to wrap everything
				theReel.wrap('<div class="hideArrowCont" />');
				var parentWidth = parseInt($(this).parent().width()) - (parseInt(prevArrow.css('padding-left')) * 2) - (parseInt(prevArrow.css('padding-right')) * 2);
				$(this).find($('div.hideArrowCont')).css({ 'overflow': 'hidden', 'width': (parentWidth - (prevArrow.outerWidth() * 2)), 'left': (prevArrow.outerWidth()), 'position': 'relative', 'height': ($('div.hideArrowCont').parent().height()) });
				//change css of parent container
				var newParentWidth = mainParContWidth - (parseInt(prevArrow.css('width')) * 2);
				//width of individual slides - they must all be the same width to work
				theSlides.outerWidth(theReel.parent().width());
				slideWidth = theSlides.outerWidth();
			}
			else {
				//width of individual slides - they must all be the same width to work
				theSlides.outerWidth(theReel.parent().width());
				slideWidth = theSlides.outerWidth();
			}
			var currSlide = 0;

			//amount of slides
			var amtSlides = theSlides.length;
			//the timers if using timer animation
			var slideTimeOut;
			var allSlidesWidth;
			//set width of reel so it holds all slides side by side
			if (resizeArrows) {
				prevArrow.height(theSlides.eq(0).height());
				nextArrow.height(theSlides.eq(0).height());
			}

			if (resize) {
				theSlides.has('img').width(theReel.parent().width());
				theSlides.width(theReel.parent().width()); //match the image size to the reel
				slideWidth = theSlides.outerWidth();
				allSlidesWidth = amtSlides * slideWidth;
				theReel.width(allSlidesWidth);
				theReel.css({ 'position': 'relative' });
				$(window).resize(function (e) {
					theSlides.width(theReel.parent().width()); //match the image size to the reel
					slideWidth = theSlides.outerWidth();
					allSlidesWidth = amtSlides * slideWidth; //calculate the reel length = number of slides X the slide width
					theReel.width(Math.floor(allSlidesWidth)); //add calculated width to the reel
					if (currSlide !== 0) {
						theReel.css({ 'left': -((currSlide * slideWidth) - 1) }); //calculate the current slide position
					} else {
						theReel.css({ 'left': 0 });
					}
					if (resizeArrows) {
						prevArrow.height(theSlides.eq(0).height());
						nextArrow.height(theSlides.eq(0).height());
					}
				});
			} else {
				allSlidesWidth = amtSlides * slideWidth;
				theReel.width(allSlidesWidth);
				theReel.css({ 'position': 'absolute' });
			}
			if (theSlides.length === 1) {
				prevArrow.hide();
				nextArrow.hide();
			}
			//check if prev arrow exists
			if (prevArrow === null) {
				//
			}
			else {
				if (hideArrows === true) {
					prevArrow.hover(
					function () {
						$(this).stop(true).fadeTo(1000, 0.8);
					},
					function () {
						$(this).stop(true).fadeTo(1000, 0);
					}
				);
				}
				//LEFT ARROW ctrl
				prevArrow.click(function () {
					if (useTimer === true) {
						resetTimer();
					}
					if (currSlide < 1) {
						//move to the last slide in the rotation
						currSlide = (amtSlides - 1);
						theReel.animate({ 'left': -(currSlide * slideWidth) }, animTime, 'swing');
					}
					else {
						currSlide--;
						theReel.animate({ 'left': -(currSlide * slideWidth) }, animTime, 'swing');
					}
					//change tab/btns when arrow is clicked
					//array through tabs
					if (tabNav !== null) {
						updateTabs();
					}
				});
			}

			//check if next arrow exists
			if (nextArrow === null) {
				//
			}
			else {
				if (hideArrows === true) {
					nextArrow.hover(
					function () {
						$(this).stop(true).fadeTo(1000, 0.8);
					},
					function () {
						$(this).stop(true).fadeTo(1000, 0);
					}
				);
				}
				//RIGHT ARROW ctrl
				nextArrow.click(function () {

					if (useTimer === true) {
						resetTimer();
					}
					if (currSlide > (amtSlides - 2)) {
						//go back to the first slide
						currSlide = 0;
						theReel.animate({ 'left': -(currSlide * slideWidth) }, animTime, 'swing');
					}
					else {
						currSlide++;
						theReel.animate({ 'left': -(currSlide * slideWidth) }, animTime, 'swing');
					}
					//change tab/btns when arrow is clicked
					//array through tabs
					if (tabNav !== null) {
						updateTabs();
					}
				});
			}

			//check if tabbed nav exists
			if (tabNav === null) {
				//
			}
			else {
				//loop through each tab/btn
				tabNav.each(function (index) {
					//individual tab clicked
					$(this).click(function () {
						if (useTimer === true) {
							resetTimer();
						}
						//set all tab classes back to normal
						tabNav.removeClass('active');
						//add active class to this tab only
						$(this).addClass('active');
						//move the reel
						theReel.animate({ 'left': -(index * slideWidth) }, animTime, 'swing');
						//change currSlide
						currSlide = index;
					});
				});
			}

			//if using timer
			if (useTimer === true) {
				slideTimeOut = setTimeout(changeSlide, timerTick);
			}

			var _resetSlides = function () {
				//public accessible function to update slides for radio/slide interactivity
				//set current slide to first slide
				currSlide = 0;
				//set slide position to show first slide
				theReel.css({ 'left': 0 });
				//calculate the amount of active slides
				amtSlides = calcActiveSlides();
				//calculate the cumulative width of all active slides
				allSlidesWidth = amtSlides * slideWidth;
				//set real width to cumulative with
				theReel.width(allSlidesWidth);
				//reset arrows to show
				prevArrow.show();
				nextArrow.show();
				//if only one slide set, hide arrows
				if (amtSlides === 1) {
					prevArrow.hide();
					nextArrow.hide();
				}
			};
			function updateTabs() {
				tabNav.removeClass('active');  //remove active class from all tabs
				tabNav.eq(currSlide).addClass('active');  //make current tab active
			}
			function calcActiveSlides() {
				var cntActiveSlides = 0;
				theSlides.each(function () {
					if ($(this).hasClass('active')) {
						cntActiveSlides++;
					}
				});
				return cntActiveSlides;
			}

			function changeSlide() {
				if (useTimer === true) {
					clearTimeout(slideTimeOut);
				}
				//                slideTimeOut = setTimeout(changeSlide, timerTick);
				if (currSlide > (amtSlides - 2)) {
					//go back to the first slide
					currSlide = 0;
					theReel.animate({ 'left': -(currSlide * slideWidth) }, animTime, 'swing', function () {
						slideTimeOut = setTimeout(changeSlide, timerTick);
					});
				}
				else {
					currSlide++;
					theReel.animate({ 'left': -(currSlide * slideWidth) }, animTime, 'swing', function () {
						slideTimeOut = setTimeout(changeSlide, timerTick);
					});
				}
				if (tabNav !== null) {
					updateTabs();
				}
			}


			function resetTimer() {
				//cancel the timer so slides stop animating
				clearTimeout(slideTimeOut);
				//start a new timer to double the length of the original timer, so the user has time view the slide
				slideTimeOut = setTimeout(changeSlide, (15000));
			}
			$(this).data('ashFlexSlide', { resetSlides: _resetSlides });
		}); //return
	};

	$.fn.ashAccord = function (options) {

		var defaults = {
			trigger: '.ashAccordTrigger', //ele that triggers the accordion
			triggerNewText: null,
			content: '.ashAccordContent', //content that hides/shows
			callback: null
		};

		var s = $.extend(defaults, options);

		return this.each(function () {
			var tr = $(this).find($(s.trigger)),
				newTxt = s.triggerNewText,
				callback = s.callback,
				content = $(this).find($(s.content));

			//if changing trigger html, save the original html in a var so content can toggle
			if (newTxt) {
				var origTxt = tr.html();
			}

			function changeText() {
				if (newTxt) {
					if (content.is(':visible')) {
						//if content is open, show alt text
						tr.html(newTxt);
					} else {
						//show default text
						tr.html(origTxt);
					}
				}
			}

			tr.on('click.ashAccord', function (e) {
				e.preventDefault();
				content.slideToggle({
					complete: function () {
						changeText();
						if (callback) {
							s.callback.call(this);
						}
					}
				});
			});
		});
	};

	$.fn.ashCollapsible = function (options) {
		var defaults = {
			obj: this, //clickable header element,
			panel: null, //collapsible panel element (defaults to the next() element
			startOpen: false, //should the panel be open on page load?
			iconContainer: 'span', //element that holds the icon class
			closedIconClass: null, //icon displayed when collapsible panel is open
			openedIconClass: null, //icon displayed when collapsible panel is closed
			keepOpen: true, //enable the ability to keep the collapsible panel open
			keepOpenMinMax: 'max', //min-width or max-width of browser window
			keepOpenWidth: 600, //width of browser window
			callbackExpand: null, //callback on panel expand
			callbackCollapse: null //callback on panel collapse
		};

		var s = $.extend({}, defaults, options);

		var panel = s.panel || s.obj.next(),
			altText = s.headerAltText,
			hideHdr = s.obj.data('hide') || false;
		
		return this.each(function () {
			var obj = $(this);

			//set initial height
			if (s.startOpen) {
				obj.addClass('active');
				panel.addClass('active');
			}

			obj.on('click', function (evt) {
				evt.preventDefault();
				var thisTrigger = $(this),
					panel = (s.panel) ? s.panel : thisTrigger.next();
				
				if (!panel.hasClass('active') || obj.hasClass('keep-open')) {
					obj.addClass('active');
					panel.addClass('active');
					if (s.callbackExpand) {
						panel.one('transitionend', s.callbackExpand());
					}
					if (hideHdr) { s.obj.hide(); }
				} else {
					obj.removeClass('active');
					panel.removeClass('active');
					if (s.callbackCollapse) {
						panel.one('transitionend', s.callbackCollapse());
					}
				}
			});

			if (s.keepOpen === true) {
				$(window).on('load orientationchange resize', function () {
					//keep tab open when browser width is under 600px
					if (Modernizr.mq('(' + s.keepOpenMinMax + '-width: ' + s.keepOpenWidth + 'px)')) {
						obj.addClass('keep-open');
					} else {
						obj.removeClass('keep-open');
					}
				}); //end win load
			}
		});
	};

	$.fn.ashExpDataGallery = function (opts) {
		var defaults = {
			finder: '.exp-data-gallery-img',
			activeClass: 'active',
			obj: $(this),
			delayedFunction: null,
			contentTriggers: $(this).find($('.exp-data-gallery-item')),
			allContentTriggers: $('.exp-data-gallery-item'),
			contentItems: $(this).find($('.exp-data-item')),
			allContentItems: $('.exp-data-item'),
			mobileFirst: false,
			callbackExpand: null,
			callbackCollapse: null
		};

		var o = $.extend(defaults, opts),
			obj = o.obj,
			delayedFunction = o.delayedFunction;

		return this.each(function () {
			$(document).on('click', 'a.close', function (e) {
				e.preventDefault();
				var openPanel = $(this).closest('[data-ash-edg-info]');
				openPanel.slideUp(350).attr('aria-expanded', 'false');
				o.allContentTriggers.removeClass(o.activeClass);
				if (typeof o.callbackCollapse === 'function') {
					o.callbackCollapse();
				}
			});

			obj.on('click', '.exp-data-gallery-item', function (e) {
				e.preventDefault();
				var obj = $(this),
					//div inside list item that user is clicking
					dataFinder = obj.find(o.finder).attr('data-ash-edg'),
					//expanding element that matches dataFinder
					expandable = obj.parents('.exp-data-gallery').find('[data-ash-edg-info="' + dataFinder + '"]'),
					scrollPoint = ((expandable.offset().top) - ($('.exp-data-gallery-item').height()) - 50);

				//close any expanded items on the page
				o.allContentItems.slideUp(350).attr('aria-expanded', 'false');
				o.allContentTriggers.removeClass(o.activeClass).attr('aria-selected', 'false');

				if (expandable.is(':visible')) {
					//close only the expanded item that is tied to the item that was clicked
					expandable.slideUp(350).attr('aria-expanded', 'false');
					obj.removeClass(o.activeClass).attr('aria-selected', 'false');
				}
				else {
					obj.addClass(o.activeClass).attr('aria-selected', 'true');
					expandable.delay(400).slideDown(350, function () {
						//if expanded info is below window
						if (($(window).height()) < ((expandable.offset().top) + (expandable.height()))) {
							$('html, body').animate({ scrollTop: scrollPoint }, 500);
						}
						if (delayedFunction) {
							delayedFunction();
						}
						//only call callback if the active class has been added to the clicked object
						if (typeof o.callbackExpand === 'function' && obj.hasClass(o.activeClass)) {
							o.callbackExpand();
						}
					}).attr('aria-expanded', 'true');
				}
			});

			$(window).on('load', function () {
				if (typeof $$ashSc === 'undefined') {
					if (Modernizr.mq($$ash.mq('sm'))) {
						o.contentItems.each(function (i) {
							i = (i + 1) * 2;
							var ind = String(i);
							$(this).css({ 'order': ind });
						});

						o.contentTriggers.each(function (i) {
							i = (i * 2) + 1;
							var ind = String(i);
							$(this).css({ 'order': ind });
						});
					}
				}
			});

		});
	};

	$.fn.ashFileUpload = function (options) {
		var targetImg = options.targetImage,
			action = options.action;

		$(this).ajaxfileupload({
			'action': action,
			'onComplete': function (response) {
				var jsonData = $$ash.unstringJSON(response);

				targetImg.attr("src", jsonData.imgUrl);
			}
		});
	};

    // Remove this once #87579 is fully integrated - JW
	$.fn.progressGraphInit = function (donutWidth, callback) {
		var jsonData,
			obj = $(this),
			src = obj.attr('data-json-url');
		$.when(
			$.getJSON(src, function (data) {
				jsonData = data;
			})
		).then(
			//success
			function (response, responseText, xhr) {
				$$ash.reloadOnTimeout(xhr);

				if (jsonData.error === true) {
					$$ash.ajaxFail(obj, jsonData.errorMessage);
				} else {
					var r = Raphael(obj.attr('id')),
						values = [],
					    newTotal; //total used when total is a large number and adds too many section to the pie chart

					//ASH added to change donut totals to array to prevent page from freezing due to too much data 
					newTotal = (jsonData.percent) ? 100 : jsonData.total;

					for (var i = 0; i < newTotal; i++) {
						values.push(1);
					}

					donutWidth = (donutWidth <= $(obj).height()) ? donutWidth : $(obj).height();
					var pie = r.piechart((donutWidth * 0.5), (donutWidth * 0.5), donutWidth, values, {
						donut: true,
						donutMet: jsonData.met,
						donutMetPercent: jsonData.percent,
						goalMet: jsonData.goalMet,
						showMax: jsonData.showMax,
						donutUnit: jsonData.unit,
						donutAction: jsonData.action,
                        donutTotal: jsonData.total,
						metSvg: '<svg style="display:none;" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve"> <use overflow="visible" xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="/Global/images/Base/Icon/Icons_Sprite_Challenges.svg#trophy" /> </svg>',
						metHtml: '<div class="no-svg icon-goalMet"></div> <div class="donut-met-cont"><svg preserveAspectRatio="xMinYMin meet"> <use xlink:href="/Global/images/Base/Icon/Icons_Sprite_Challenges.svg#trophy"></use> </svg></div>',
						reverseColors: obj.hasClass('donut-reverse-colors') === true ? true : false
					});

					if (typeof callback === 'function') {
						callback();
					}
				}
			},
			//fail
			function (xhr) {
				$$ash.ajaxFail(obj, undefined, xhr);
			}
		);
	};

	$.fn.initViewMoreButton = function (options) { //context in which to look for buttons
		var defaults = {
			callback: null,
			keyToSlice: null,
			numberToSlice: null,
			startSliceIndex: 0,
			insertType: null,
			jsonData: null //option to pass in JSON data. If not passed in, then script will grab JSON from url specified in data-ash-json-url
		};

		var opts = $.extend({}, defaults, options);

		return this.each(function () {
			var obj = $(this);

			obj.off('click.viewMoreInit').one('click.viewMoreInit', function () {
				opts.obj = obj;

				$$ash.viewMore(opts);
			});
		});
	};

	$.fn.ajaxSearchInit = function (options) {
		var defaults = {
			numberOfResults: parseInt(this.attr('data-number-of-results')) || 10, //number of results to show initially
			keyToSlice: this.attr('data-key-to-slice') || 'results'
		};

		var opts = $.extend({}, defaults, options);

		return this.each(function () {
			var obj = $(this);

			obj.on('submit.ajaxSearch', function (e) {
				var search = obj.find('input[type="search"]'),
					//submit = obj.find('input[type="submit"]'),
					target = opts.targetId || obj.attr('data-ash-target-id'), //ID of DOM element to insert search results into
					keyToSlice = opts.keyToSlice, //which key in the json to slice
					numberOfResults = opts.numberOfResults,
					finalCallback = opts.callback;

				if (!target) {
					throw ('You MUST supply a targetId and keyToSlice!');
				}

				e.preventDefault();

				$$ash.removeButtonOnSubmit(obj);

				$$ash.ajaxFormSubmit(obj, {
				    data: search.val(),
					url: obj.attr('action'),
					callback: function (response, textStatus, xhr) {
					    var jsonData = $$ash.unstringJSON(response),
							cacheFullArray;

						if (numberOfResults) {
							if (!keyToSlice) {
								throw ('You MUST supply a key to slice!');
							} else {
								cacheFullArray = jsonData[keyToSlice];
								jsonData[keyToSlice] = jsonData[keyToSlice].slice(0, numberOfResults);
							}
						}

						$$ash.handlebarsInit(obj, {
							target: target,
							jsonData: jsonData,
							callback: function () {
								if (numberOfResults) {
									jsonData[keyToSlice] = cacheFullArray;
								}

								$('#' + obj.attr('data-ash-target-id')).find('.viewMore').initViewMoreButton({
									jsonData: jsonData,
									startSliceIndex: numberOfResults,
									callback: finalCallback
								});
							}
						});
					}
				});
			});
		});
	};

	$.fn.returnCheckedInputsAsObject = function () {
		var form = $(this),
			checked = form.find('input:checked'),
			object = {};

		checked.each(function () {
			var input = $(this),
				selName = input.attr('name'),
				selVal = input.val();

			object[selName] = selVal;
		});

		return object;
	};

	$.fn.disableButtonDuringAjaxPost = function (opts) { //context in which to look for buttons
		var submitButton = opts.submitButton,
			isTrueButton = submitButton.is('button'),
			originalText = isTrueButton ? submitButton.html() : submitButton.val(),
			newText = opts.loadingText;

		if (!submitButton) {
			throw new Error("Please verify you are passing in a button disable");
		} else {
			if (submitButton instanceof jQuery === false) {
				throw new Error("Please verify you are passing in a button disable as a jQuery object");
			}
		}

		submitButton.prop('disabled', true);
		
		if (isTrueButton === true) {
			submitButton.html(newText);
		} else {
			submitButton.val(newText);
		}

		$(document).one('$$ash:ajaxComplete', function () {
			if (isTrueButton === true) {
				submitButton.html(originalText);
			} else {
				submitButton.val(originalText);
			}

			if (submitButton.data('stay-disabled') !== true) {
				submitButton.prop('disabled', false);
			}
		});
	};

	$.fn.ashTemplateInit = function (options) { // opts = options
		
		return this.each(function () {
			var obj = $(this),
				opts = options || {},
				target = opts.target || obj.attr('data-target-id') || obj.attr('data-ash-target-id'),
				templateUrl = opts.templateUrl || obj.attr('data-template-url') || obj.attr('data-ash-template-url'),
				jsonUrl = opts.jsonUrl || obj.attr('data-json-url') || obj.attr('data-ash-json-url'),
				jsonData = opts.json ? $$ash.unstringJSON(opts.jsonData) : null, // this will overwrite the jsonUrl option above
				callback = opts.callback;

			$$ash.getHandlebarsTemplateAndJson(obj, {
				templateUrl: templateUrl,
				jsonUrl: jsonUrl,
				jsonData: jsonData,
				callback: function (data) {
					$$ash.renderTemplate(obj, data.template, data.json, target, callback);
				}
			});
		});
	};

	$.fn.isOnScreen = function () {
		var viewport = {},
			bounds = {};

		viewport.top = $(window).scrollTop();
		viewport.bottom = viewport.top + $(window).height();

		bounds.top = this.offset().top;
		bounds.bottom = bounds.top + this.outerHeight();

		return ((bounds.top <= viewport.bottom) && (bounds.bottom >= viewport.top));
	};
})(jQuery);

//DATE CONTROLLER BUILDER
var DateController = function () {
	var dateControl = this,
		jsonCurrUrl;

	dateControl.needCustomSetup = true;
	dateControl.custForm = '#custForm';
	dateControl.ctrlFmt = 'mmmddyyyy';

	dateControl.initialize = function (obj) {
		//setup date controller by getting the current json from the src link from the attr 
		dateControl.ctrlSel = $('.' + obj + '-select');

		jsonCurrUrl = dateControl.ctrlSel.attr('data-current-json-url');

		return jsonCurrUrl;
	};
	dateControl.getData = function (ctlrObj, json) {
		if (!dateControl.ctrlSel) {
			dateControl.ctrlSel = ctlrObj;
		}
		$.getJSON(json, function (data) {
			//pull date data
			var startDate = data.summary.startDate,
				endDate = data.summary.endDate,
				previous = data.summary.previous,
				next = data.summary.next;
			if (json.error) {
				$$ash.ajaxFail(ctlrObj, data.errorMessage);
			} else {
				//setup control
				dateControl.setupDateCtrl(ctlrObj, startDate, endDate, previous, next);
			}

		}).fail(function (xhr) {
			$$ash.ajaxFail(ctlrObj, undefined, xhr);
			return false;
		});
	};
	dateControl.moveToNewDate = function (dateObj, newArrow, oldArrow) {
		oldArrow.attr('data-json-url', dateObj.attr('data-current-json-url'));
		dateObj.attr('data-current-json-url', newArrow.attr('data-json-url'));
	};
	dateControl.setupDateCtrl = function (dateObj, startDate, endDate, previous, next) {
		if (!dateControl.ctrlSel) {
			dateControl.ctrlSel = dateObj;
		}
		//setup date controller adding prev / next arrow btns and start and end dates
		var prevDate = dateObj.find('.prev[data-json-url]'),
			nextDate = dateObj.find('.next[data-json-url]'),
			datePeriod = dateObj.find('.date-period'),
			dateType = dateObj.attr('data-date-type') ? dateObj.attr('data-date-type') : dateControl.ctrlFmt;
		//save start and end date
		datePeriod.attr('data-date-start', startDate);
		datePeriod.attr('data-date-end', endDate);

		dateControl.resetArrows(prevDate, nextDate); //reset arrows
		//hide arrow if at the end of the data for each time period
		if (!previous) {
			previous = 'null';
			prevDate.addClass('arrowOff');
		}
		prevDate.attr('data-json-url', previous);
		if (!next) {
			next = 'null';
			nextDate.addClass('arrowOff');
		}
		nextDate.attr('data-json-url', next);
		//when arrows are selected, reset them
		dateObj.find('[data-json-url]').on('click', function (e) {
			e.preventDefault();
			dateControl.resetArrows(prevDate, nextDate);
		});
		//set up text for date period
		dateControl.displayDate(startDate, endDate, dateType);
	};
	dateControl.resetArrows = function (prevDate, nextDate) {
		prevDate.removeClass('arrowOff');
		nextDate.removeClass('arrowOff');
	};
	dateControl.displayDate = function (startDate, endDate, dateType) {
		var sDtConv = $.trim(dateControl.convertDate(startDate, dateType)),
			eDtConv = $.trim(dateControl.convertDate(endDate, dateType)),
			dtPer = dateControl.ctrlSel.find('.date-period');

		if (sDtConv === eDtConv) {
			dtPer.text(sDtConv);
		} else {
			dtPer.text(sDtConv + ' - ' + eDtConv);
		}
	};
	dateControl.convertDate = function (d, type) {  //convert json date to date control readable date
		if (d === undefined || type === undefined) {
			return;
		}
		if (d.indexOf('T') === -1) {
			d += 'T00:00:00';  //if not time in datetime stamp add one
		}

		var dt = d.split('T'), //split datetime from yyyy-mm-ddThh:mm:ss.ms
			dParts = dt[0].split('-'), //split date yyyy-mm-dd
			convDate = new Date(dParts[0], dParts[1] - 1, dParts[2]);  //Date(yyyy, mm - 1, dd) sets the date

		var month = [];
		month[0] = "January";
		month[1] = "February";
		month[2] = "March";
		month[3] = "April";
		month[4] = "May";
		month[5] = "June";
		month[6] = "July";
		month[7] = "August";
		month[8] = "September";
		month[9] = "October";
		month[10] = "November";
		month[11] = "December";

		switch (type) {
			case 'mmmddyyyy':
				//example: January 12, 2014
				return month[convDate.getMonth()] + ' ' + convDate.getDate() + ', ' + convDate.getFullYear();
			case 'mmmyyyy':
				//example: January 2014
				return month[convDate.getMonth()] + ' ' + convDate.getFullYear();
			case 'mm/dd/yyyy':
				//example: 01/12/2014
				var mo = (convDate.getMonth() + 1),
					moStr = mo <= 9 ? '0' + mo.toString() : mo,
					da = convDate.getDate(),
					daStr = da <= 9 ? '0' + da.toString() : da;
				return moStr + '/' + daStr + '/' + convDate.getFullYear();
			default:
				//example: January 12, 2014
				return month[convDate.getMonth()] + ' ' + convDate.getDate() + ', ' + convDate.getFullYear();
		}
	};
};
