function setupAshModal(el, cbOnClose, cbOnClick) {
	if (el.length) {
		//if element has data-ash-ashmodalformat attribute
		var obj = el.attr('data-ash-ashmodalformat') ? el : $('.modalOpen');

		obj.each(function () {
			var obj = $(this),
				modalMatch = $(
					'.ashModalCont[data-ash-ashmodalmatch="' +
						obj.attr('data-ash-ashmodalmatch') +
						'"]'
				),
				modalClass = obj.attr('data-ash-addmodalclass')
					? obj.attr('data-ash-addmodalclass')
					: null;
			obj.ashModal({
				theContent: modalMatch,
				closeContent: '<span class="icon-closeExit" aria-hidden="true"></span>',
				addModalClass: modalClass,
				callbackAfterClick: cbOnClick,
				callbackAfterClose: cbOnClose,
				removePageScroll: true,
			});
		});
	}
}

document.addEventListener('DOMContentLoaded', function () {
	navigation();
	$$ash.metaNavInit(
		'.secondaryInfo .user-info a.dropdown-menu-trigger',
		'.secondaryInfo .user-info .dropdown-menu-cont'
	);

	//set up ashModal
	setupAshModal($('.modalOpen'));
	//initialize session timer
	if (
		document.querySelectorAll('.isHome').length === 0 &&
		document.querySelectorAll('.isPublic').length === 0
	) {
		var timeout = window.$$sessionTimeOut || 20;
		$$ash.sessionTimerInit({
			delayMins: timeout,
			countSecs: 30,
		});
	}
});
